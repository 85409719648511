<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import userGetFindById from "../../../state/api/userGetFindById";
import changePasswordProcess from "../../../state/api/changePasswordProcess";
/**
 * Contacts-Profile component
 */
export default {
  page: {
    title: "Kullanıcı",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader},
  data() {
    return {
      title: "Kullanıcı",
      items: [
        {
          text: "Kullanıcılar",
          href: "/"
        },
        {
          text: "Kullanıcı",
          active: true
        }
      ],
      userDetail :"",
      id:this.$route.params.id,
      changePasswordError:""
    };
  },
  methods:{
    async userGetFindById(){
        const {getUserDetail} = userGetFindById()
        const response = await getUserDetail(this.id);
        this.userDetail = response.data;
    },
    changePassword(){
      async function postChangePassword() {
          const {getChangePasswordProcess} = changePasswordProcess()
          await getChangePasswordProcess().
          then(res=>{
              if(res.isSuccess ==true){
                this.$router.push({ name: "email-verification" });
              }
              else{
                this.changePasswordError = res.errorMessage
              }
          })
      }
      postChangePassword.bind(this)();
    }
  },
  mounted(){
    this.userGetFindById();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{$t('user.PersonalInformation')}}</h4>
            <div class="table-responsive ">
              <table class="table table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">{{$t('user.FullName')}} :</th>
                    <td>{{ userDetail.firstName }} {{ userDetail.lastName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{$t('user.Email')}} :</th>
                    <td>{{ userDetail.userName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{$t('user.Phone')}} :</th>
                    <td>{{userDetail.phone}}</td>
                  </tr>
                  <!-- <tr>
                    <th scope="row">{{$t('user.UserType')}} :</th>
                    <td>{{userDetail.userType.name}}</td>
                  </tr> -->
                  <tr>
                    <td>
                      <button @click="changePassword" class="btn btn-primary">Şifre Değiştir</button>
                      
                    </td>
                  </tr>                  
                </tbody>
              </table>
              <p class="text-danger text-center">{{ changePasswordError }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>